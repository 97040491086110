export const RESULT = {
  STATUS: 'status',
  SEEN: 'seen',
  RESULTS: 'results',
  HAZARD_PERCEPTION: 'hazard_perception',
  KNOWLEDGE: 'knowledge',
  HAS_PASSED: 'has_passed',
  INSIGHT: 'insight',
  MISTAKE: 'mistake',
  HAS_ZERO_MISTAKES: 'has_zero_mistakes',
  HAS_NOT_CAME: 'has_not_came',
  CAN_BE_LOADED: 'can_be_loaded',
  HAS_MANY_MISTAKES: 'has_many_mistakes',
  CORRECT_ANSWERS: 'correct_answers'
};

export const RESULT_STATUS = {
  FETCHING: 'Fetching',
  LOADED: 'Loaded',
  FAILED: 'Failed'
};

export default class ResultReservedExam {
  constructor(item = {}) {
    this.status = item[RESULT.STATUS] || '';
    this.seen = item[RESULT.SEEN] || '';
    this.results = item[RESULT.RESULTS] || '';
    this.hazard_perception = item[RESULT.HAZARD_PERCEPTION] || '';
    this.knowledge = item[RESULT.KNOWLEDGE] || '';
    this.insight = item[RESULT.INSIGHT] || '';
    this.mistake = item[RESULT.MISTAKE] || '';
    this.has_passed = item[RESULT.HAS_PASSED] || '';
    this.has_zero_mistakes = item[RESULT.HAS_ZERO_MISTAKES] || false;
    this.has_not_came = item[RESULT.HAS_NOT_CAME] || '';
    this.can_be_loaded = item[RESULT.CAN_BE_LOADED];
    this.correct_answers = item[RESULT.CORRECT_ANSWERS];
  }

  getApiData() {
    return {
      results: this.results,
      [RESULT.HAS_ZERO_MISTAKES]: this.has_zero_mistakes
    };
  }
}

export class ResultReservedExamFormValidationFields {
  constructor() {
    this.hazard_perception = 'hazard_perception';
    this.results = 'results';
    this.knowledge = 'knowledge';
    this.insight = 'insight';
  }
}
